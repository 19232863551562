.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .container {
    flex-direction: row;
  }

  .input,
  .output {
    width: 50%;
  }
}

.input {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 16px;
  margin-bottom: 16px;
  outline: none;
  border: hidden;
  border-right: 1px solid #ccc;
}

.output {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 16px;
  margin: 0;
  white-space: pre-line;
}

.tools {
  position: absolute;
  top: 8px;
  right: 16px;
  z-index: 10;
}

.tools > * {
  margin-right: 8px;
}

.tools .fontsize-input {
  width: 60px;
}

.reference-link {
  font-size: 12px;
  color: black;
}
