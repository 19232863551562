.bodyModalOpen {
  overflow: hidden;
}

.container {
  color: #555555;
  background: #1f2022;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: scroll;
  font-weight: bold;
}

.title {
  font-size: 5em;
}

.lyrics {
  font-size: 3em;
}

.title,
.stanza {
  transition: 0.3s color ease;
  cursor: pointer;
}

.title:hover,
.stanza:hover {
  color: #777777;
}

.title.active,
.stanza.active {
  color: #dddddd;
}

.stanza {
  margin: 1em;
}

.stanza.chorus {
  font-style: italic;
}

.closeIcon {
  position: fixed;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.bottomRight {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  align-items: flex-end;
}

.bottomRight > * {
  margin-bottom: 12px;
}

.fullScreenIcon,
.fzPlusIcon,
.fzMinusIcon,
.fzReset {
  cursor: pointer;
}

html:fullscreen .fullScreenIcon {
  display: none;
}

html:fullscreen .closeIcon {
  display: none;
}
