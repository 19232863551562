.editContainer {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 640px) {
  .editContainer {
    flex-direction: row;
  }

  .editArea,
  .previewArea {
    width: 50%;
  }
}

.editArea {
  padding-right: 8px;
}

.previewArea {
  padding-left: 8px;
}

.editArea textarea {
  height: 100%;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}

.editArea,
.previewArea {
  margin-bottom: 8px;
}

.pathSaveCancelContainer {
  display: flex;
  margin-bottom: 8px;
}

.filePathInput {
  flex: 1;
  margin-right: 8px;
}
