.title {
  line-height: 1.6em !important;
  font-size: 2em !important;
}

.subtitle {
  line-height: 1em !important;
  font-size: 1em !important;
  font-style: italic;
  opacity: 0.6;
}

.toolbar {
  width: 100%;
  margin-bottom: 16px;
}

.songToolbar {
  align-items: center;
  display: flex;
  font-size: inherit;
  margin-bottom: 16px;
}

.songToolbar :global(.bp3-tag) {
  font-size: inherit;
}

.songToolbar :global(.bp3-divider) {
  align-self: normal;
  border-color: #bfccd6;
}

.songToolbar :global(.bp3-tag) {
  min-height: 0;
  line-height: initial;
}

.song,
.toolbar {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

@-moz-document url-prefix() {
  .song,
  .toolbar {
    overflow-x: auto;
  }
}

.song::-webkit-scrollbar,
.toolbar::-webkit-scrollbar {
  display: none;
}

.paragraph {
  margin-bottom: 1em;
}
.paragraph:last-child {
  margin-bottom: 0;
}

.chordSheet {
  /*flex: 1 1 auto;*/
  font-size: 1.2em;
  line-height: 1.5;
  display: table;
}

.part {
  position: relative;
}
.index {
  position: absolute;
  top: -1em;
  left: 0;
}
.paragraphs {
}
.paragraph {
}
.line {
  display: table;
  white-space: pre;
}
.chordLine {
  display: table-row;
}
.lyricsLine {
  display: table-row;
}
.chord {
  display: table-cell;
  color: #c23030;
  font-weight: 500;
  font-family: 'Avenir Next Condensed', 'Ubuntu Condensed', 'Roboto Condensed',
    'Fira Sans Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  padding-right: 6px;
  line-height: 1;
}
:global(.bp3-dark) .chord {
  color: #ff7373;
}

.lyrics {
  display: table-cell;
}

.hideChords .chordLine {
  display: none;
}

.chorus .paragraph {
  margin-left: 20px;
}

.chorus .lyrics {
  font-style: italic;
}

.verse {
}
